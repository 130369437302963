import React from "react";
import Style from "./Home.module.scss";
import me from "../../img/self.png";
import classNames from "classnames";
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import { Box } from "@mui/material";
import { TypeAnimation } from 'react-type-animation';

const bioBullets = [
  {
   //  link: "https://www.travelwisconsin.com/",
    emoji: "🏠",
    text: "based in bhopal",
  },
  {
   //  link: "https://www.mathisonprojectsinc.com",
    emoji: "💻",
    text: "enterprise software engineer at wolters kluwer",
  },
  
  {
    link: "mailto:jayesh.pawar.004@gmail.com?subject=Let's work on something together!",
    emoji: "✉️",
    text: "let's get in touch!",
  },
];

// const socials = [
//    {
//       link: "https://github.com/paytonjewell",
//       icon: "fa fa-github",
//       label: 'Github'
//    },
//    {
//       link: "https://linkedin.com/in/payton-pierce",
//       icon: "fa fa-linkedin",
//       label: 'LinkedIn'
//    },
//    {
//       link: 'https://twitter.com/paytoncodes',
//       icon: "fa fa-twitter",
//       label: 'Twitter'
//    }
// ]

export default function Home() {
  return (
    <Box
      component={"main"}
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={"center"}
      justifyContent={"center"}
      minHeight={"calc(100vh - 175px)"}
    >
      <Box
        className={classNames(Style.avatar, Style.shadowed)}
        component={"img"}
        alt={
          "image of woman with brown curly hair and glasses smiling at the camera"
        }
        src={me}
        width={{ xs: "36vh", md: "41vh" }}
        height={{ xs: "35vh", md: "40vh" }}
        borderRadius={"50%"}
        p={"0.75rem"}
        mb={{ xs: "1rem", sm: 0 }}
        mr={{ xs: 0, md: "2rem" }}
      />
      <Box>
        <h1>
          Hi, I'm <span className={Style.firstName}>Jayesh</span>
          <span className={Style.hand}>🤚</span>
        </h1>
        <h2>I'm a <TypeAnimation
      sequence={[
        'Front end developer', // Types 'One'
        2000, // Waits 1s
        'Graphics Designer', // Deletes 'One' and types 'Two'
        2000, // Waits 2s
        'UI/UX Designer',
         5000,// Types 'Three' without deleting 'Two'
        
      ]}
      // wrapper="span"
      // cursor={true}
      repeat={Infinity}
    /></h2>
        <Box component={"ul"} p={"0.8rem"}>
          {bioBullets.map((bio, index) => (
            <li key={index}>
              <EmojiBullet link={bio.link} emoji={bio.emoji} text={bio.text} />
            </li>
          ))}
        </Box>
        {/* <Box display={'flex'} gap={'1.5rem'} justifyContent={'center'} fontSize={{xs: '2rem', md: '2.5rem'}}>
               {socials.map((social, index) => (
                  <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
               ))}
            </Box> */}
      </Box>
    </Box>
  );
}
