import React from "react";
import Style from "./About.module.scss";
import Terminal from "./Terminal";
import { Box } from "@mui/material";

export default function About() {
  function aboutMeText() {
    return (
      <>
        <p>
          <span className={Style.pink}>jayeshp $</span> aboutjp{" "}
        </p>
        <p>
          <span className={Style.pink}>
            aboutjp <span className={Style.green}>(main)</span> ${" "}
          </span>
          Hello! I'm Jayesh, a front-end developer / UI/UX Designer from Bhopal.
          I love creating web applications. I've been working for{" "}
          <a
            target={"_blank"}
            rel={"noreferrer"}
            href={"https://www.wolterskluwer.com/en-in"}
          >
            Wolters Kluwer
          </a>{" "}
          since July 2023. Previously I was working at{" "}
          <a
            target={"_blank"}
            rel={"noreferrer"}
            href={"http://www.whedia.com/"}
          >
            Whedia
          </a>{" "}
          as Front End Developer.
        </p>
        <p>
          I still don't know if it's "front-end", "frontend, or "front end", and
          at this point I'm too afraid to ask.
        </p>
      </>
    );
  }

  function skillsText() {
    return (
      <>
        <p>
          <span className={Style.pink}>jayeshp $</span> cd skills/tools
        </p>
        <p>
          <span className={Style.pink}>
            skills/tools <span className={Style.green}>(main)</span> $
          </span>{" "}
          ls
        </p>
        <p className={Style.pink}> Proficient With</p>
        <ul className={Style.skills}>
          <li>JavaScript</li>
          <li>React</li>
          <li>Angular</li>
          <li>HTML5</li>
          <li>CSS3</li>
          <li>Bootstrap</li>
          <li>Tailwind</li>
          <li>Git</li>
          <li>Github</li>
          <li>npm</li>
          <li>API Requests</li>
          <li>Figma</li>
          <li>Photoshop</li>
          <li>Illustrator</li>
          <li>Corel Draw</li>
          <li>Indesign</li>
        </ul>
        <p className={Style.green}> Exposed To</p>
        <ul className={Style.skills}>
          <li>Spring Boot</li>
          <li>Firebase</li>
          <li>AWS</li>
          <li>Azure</li>
          <li>SVN</li>
          <li>Splunk</li>
        </ul>
      </>
    );
  }

  function miscText() {
    return (
      <>
        <p>
          <span className={Style.pink}>jayeshp $</span> cd hobbies/interests
        </p>
        <p>
          <span className={Style.pink}>
            hobbies/interests <span className={Style.green}>(main)</span> $
          </span>{" "}
          ls
        </p>
        <ul>
          <li>
            <Box component={"span"} mr={"1rem"}>
              📖
            </Box>
            reading
          </li>
          <li>
            <Box component={"span"} mr={"1rem"}>
            🎮
            </Box>
            gaming
          </li>
          <li>
            <Box component={"span"} mr={"1rem"}>
              🎥
            </Box>
            movies
          </li>
          <li>
            <Box component={"span"} mr={"1rem"}>
            🚅
            </Box>
            travelling
          </li>
         
        </ul>
      </>
    );
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      mt={"3rem"}
    >
      <Terminal text={aboutMeText()} />
      <Terminal text={skillsText()} />
      <Terminal text={miscText()} />
    </Box>
  );
}
