import React from 'react';
import mock1 from '../../img/mock1.png';
import mock2 from '../../img/mock2.png';
import mock3 from '../../img/mock3.png';
import mock4 from '../../img/mock4.png';
import mock5 from "../../img/mock5.png";
import mock6 from "../../img/mock6.png"
import PortfolioBlock from "./PortfolioBlock";
import {Box, Grid} from "@mui/material";

const projects = [
    {
        image: mock5,
        live: "https://nandos1.netlify.app/",
       
        title: "Nandos (React + Firebase)"
    },
    {
        image: mock1,
        
        source: "https://github.com/paytonjewell/ReactPortfolioTemplate",
        title: "Ultimate Donuts (Angular)"
    },
    {
        image: mock2,
        live: "https://www.figma.com/file/hO94NeB0Ni3BeCgdg0bfdm/Debate?type=design&node-id=1-606&mode=design",
        
        title: "Debatable (Figma UI Design)"
    },
    {
        image: mock3,
        live: "https://www.figma.com/file/J42VR10AFq8pMQTbkF02lJ/BumbleBook?type=design&node-id=5-103&mode=design",
        title: "Bumble Book (Figma UI Design)"
    },
    // {
    //     image: mock4,
    //     live: "https://paytonjewell.github.io/gradient-generator/",
    //     source: "https://github.com/paytonjewell/gradient-generator",
    //     title: "Gradient Generator"
    // },
    // {
    //     image: mock6,
    //     live: "https://ztm-smart-brain-frontend.herokuapp.com/",
    //     title: "SmartBrain"
    // }
]

export default function Portfolio() {
    return (
        <Box>
            <Grid container display={'flex'} justifyContent={'center'}>
                {projects.map((project, index) => (
                   <Grid item xs={12} md={6} key={index}>
                       <PortfolioBlock image={project.image} live={project.live} source={project.source} title={project.title} />
                   </Grid>
                ))}
            </Grid>
        </Box>
    );
};